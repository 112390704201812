export const errorPage: any = {
  patMainMenu: "Main Navigational Menu Consumer GCSO",
  patFooterMenu: "Footer Navigation Menu Consumer GCSO",
  patFooter: "Footer DTC HTML Canada",
  exitPopup: "Retina Canada Exit Popup HTML",
  hcpSwitcher: "HCP Switcher Modal Pop Up",
  patTopNav: "Top Navigation Menu Retina Canada HTML DTC",
  patSiteLogo: "Site logos DTC",
  patSitemapLabel: "Retina DTC Sitemap - Full HTML",
  banner: "Banner - HTML - Error Page DTC - Canada",
  subhead: "Subhead error page",
  footerClassName: "common-footer error-footer",
  bodyClassName: "error-page",
  redirectLangUrl: {
    redirectUrl: {
      "en": "/error",
      "fr": "/fr/error",
    }
  }
}
