import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const ErrorStyle = styled.div`
	&.error-style {
		.sitemap {
      &:before{
        @media ${device.ipadLandscapemin} and ${device.desktopmenu}{
          top: -55px;
        }
      }
			.error-banner-title {
				@media ${device.ipadLandscapemin} {
					margin: 0 auto;
					max-width: 1208px;
					padding: 3rem 2rem 0;
				}

				@media ${device.ipadLandscapemin} and ${device.desktopmenu} {
					margin-left: 30px;
				}

				@media ${device.tablet} and ${device.laptopMedium} {
					padding: 2.5rem 11% 0;
					max-width: 815px;
				}
			}

			.sitemap-block {
				@media ${device.ipadLandscapemin} and ${device.desktopmenu} {
					margin-left: 100px;
          padding: 0px 0px 120px;
				}

				ul {
					@media ${device.ipadLandscapemin} {
            padding: 28px 43px 34px;
						margin: 0;
					}
          @media ${device.laptop}{
            padding: 0;
          }
				}
			}
		}
    .footer {
      &.common-footer {
        .o-backtotop-btn {
          &.gtm-link-internal {
            @media ${device.laptopMedium} {
              top: 120px;
            }
          }
        }
      }
    }
	}

	.mobile-banner {
    &:after {
      @media ${device.laptopMedium} {
        height: calc(100% + 10px);
      }
    }
		.internal-banner {
			@media ${device.laptopMedium} {
				height: 220px !important;
			}

			&:after {
				@media ${device.laptopMedium} {
					height: 32px;
				}
			}

			.internal-banner__image {
				.desktop-image {
					@media ${device.laptopMedium} {
						display: none !important;
					}

					img {
						@media ${device.ipadLandscapemin} {
							height: 220px;
							width: 100%;
						}
					}
				}

				.mobile-image {
					@media ${device.laptopMedium} {
						display: block !important;
					}

					img {
						@media ${device.laptopMedium} {
							height: 220px;
							width: 100%;
						}
					}
				}
			}

			.internal-banner__contant {
				.o-container {
					.internal-banner__large-title {
						@media ${device.ipadLandscapemin} {
							font-family: "Gotham", Arial, Helvetica, sans-serif;
							max-width: 100%;
							padding-top: 20px;
						}

						@media ${device.desktopStart} {
							padding: 30px 0 25px;
						}

						@media ${device.tablet} and ${device.laptopMedium} {
							position: relative;
              line-height: 38px;
              padding: 15px 1px 25px;
						}
            @media ${device.ipadLandscapemin} and ${device.midDesktopMax}{
              padding: 35px 0px 25px 30px;
            }
					}
				}
			}
		}
	}

	.sitemap {
		&.pagewrapper {
			.gatsby-image-wrapper {
				img {
					height: 220px;
				}
			}
		}

		.internal-banner {
			@media ${device.laptopMedium} {
				height: 150px;
			}

			.banner-container {
				.banner-title {
					max-width: 100%;
					padding: 30px 0px 25px;

					@media ${device.mobileMedium} {
						padding: 0;
					}

					@media ${device.mobileStart} and ${device.laptopPros} {
						padding: 20px 0px 25px;
					}


					@media ${device.tablet} and ${device.laptopMedium} {
						padding: 25px 0px;
					}

					@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
						padding: 35px 0px 25px 30px;
					}
				}
			}
		}
    .container-fluid{
      @media ${device.laptopMedium}{
        max-width: 1208px !important;
        margin-left: auto;
        margin-right: auto;
        padding: 0px 2rem;
      }
    }

		.sitemap-block {
			margin: 0 0 11rem;

			ul {
				@media ${device.laptopMedium} {
					padding: 10px 43px 34px;
				}
			}
		}

		p {
			max-width: 1208px;
			margin: 0 auto;
			padding: 3rem 2rem 0;
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 3.2rem;
			font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
      margin-bottom: 1rem;

			@media ${device.laptopMedium} {
				padding: 3.3rem 6rem 0;
			}
		}
	}

	// .footer {
	// 	.footercontainer {
	// 		margin: 0 auto;

	// 		footer {
	// 			.row {
	// 				padding:0;

	// 				.show-hide {
	// 					margin-bottom: 30px
	// 				}
	// 			}
	// 		}
	// 	}
	// }
`;

export const SitemapWrapper = styled.div`
	.esheadercontainer {
		&.errorheadercontainer {
			.error-style {
				.sitemap {
					.internal-banner {
						.banner-container {
							.banner-title {
								padding: 58px 1px 25px 0px;

								@media ${device.laptopMedium} {
									padding: 0;
								}
							}
						}
					}

					&.pagewrapper {
						.gatsby-image-wrapper {
							img {
								height: 213px;
							}
						}
					}
				}
			}

			.site-map {
				.sitemap-block {
					margin: 0 0 0 115px;


					@media ${device.laptopMedium} {
						padding: 10px 0 0;
						margin: 0;
					}

					ul {
						li {
							a {
								text-decoration-thickness: unset;
								font-size: 2.2rem;
    						line-height: 3.2rem;

								@media ${device.laptopMedium} {
									font-size: 1.8rem;
    							line-height: 2.2rem;
								}
							}
						}
					}
				}
			}
		}
		.error-style {
			.error-banner-title {
				padding: 3rem 1.2rem 0 127px;

				@media ${device.laptopMedium} {
					padding: 2.5rem 5rem 0;
					margin: 0;
				}

				@media ${device.tablet} and ${device.ipadLandscape} {
					padding: 2.5rem 5.4rem 0;
				}
			}
		}

		// .footer {
		// 	.footercontainer {
		// 		padding-top: 0;

		// 		footer {
		// 			>.row {
		// 				padding-top: 120px !important;

		// 				@media ${device.laptopMedium} {
		// 					padding-top: 60px !important;
		// 				}

		// 				.show-hide {
		// 					display: none;
		// 				}
		// 			}
		// 		}
		// 	}
		// }

		.privacywrapper {
			.footer {
				.o-backtotop-btn {
					@media ${device.laptopMedium} {
						top: -74px;
					}

					&.o-back-totop-privacy {
						top: unset;

						&:hover {
							color: #8000BE;
						}
					}
				}
			}
		}

		.header-inner {
			ul {
				&.navbar-nav {
					@media ${device.extraLargeDesktop} {
						left: 40px;
					}

					li {
						&.navitemstyle {
							@media ${device.extraLargeDesktop} {
								padding: 0 3rem;
							}
						}
					}
				}
			}
		}
	}
`;
